var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { class: ["comment-list", _vm.scrollable ? "scroll" : ""] }, [
      _c(
        "div",
        { staticClass: "detail-label mb-2" },
        [
          _c("span", [
            _vm._v(
              _vm._s(_vm.$t("comment.title")) +
                " (" +
                _vm._s(_vm.notes.length) +
                ")"
            ),
          ]),
          _vm.canAdd() && _vm.showAdd && !_vm.readOnly
            ? [
                _c(
                  "button",
                  {
                    staticClass: "btn-action",
                    attrs: { id: "BTN_ADD_" + _vm.id },
                    on: { click: _vm.noteAdd },
                  },
                  [
                    _c("font-awesome-icon", {
                      attrs: { icon: ["far", "plus"] },
                    }),
                  ],
                  1
                ),
                _c(
                  "b-popover",
                  {
                    attrs: {
                      target: "BTN_ADD_" + _vm.id,
                      triggers: "hover",
                      placement: "top",
                      boundary: "viewport",
                    },
                  },
                  [
                    _vm._v(
                      " " + _vm._s(_vm.$t("comment.button.add_comment")) + " "
                    ),
                  ]
                ),
              ]
            : _vm._e(),
        ],
        2
      ),
      _c(
        "ul",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.notes.length > 0,
              expression: "notes.length > 0",
            },
          ],
          ref: "comments",
          staticClass: "mt-1 comment-container",
        },
        [
          _vm._l(_vm.notes, function (note, index) {
            return [
              _c("Note", {
                key: index,
                attrs: {
                  readOnly: _vm.readOnly,
                  note: note,
                  authorMap: _vm.authorMap,
                  requestState: _vm.requestState,
                },
                on: { edit: _vm.edit, toRemove: _vm.toRemove },
              }),
            ]
          }),
        ],
        2
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }