var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: [_vm.styleClass], attrs: { id: _vm.id } },
    [
      !_vm.suppressLabel
        ? _c("label", { class: [_vm.labelStyleClass] }, [
            _vm._v(_vm._s(_vm.$t("field.color"))),
          ])
        : _vm._e(),
      _c(
        "div",
        { staticClass: "d-flex select-button" },
        [
          _c("div", {
            staticClass: "color-style color-foreground",
            class: _vm.value === null || _vm.value === "" ? "color-none" : "",
            style:
              _vm.value === null || _vm.value === ""
                ? ""
                : `background-color: ${_vm.value}`,
            attrs: { id: `COLOR_FOREGROUND_${_vm.id}` },
            on: { click: _vm.onClick },
          }),
          _c("b-popover", {
            attrs: {
              target: `COLOR_FOREGROUND_${_vm.id}`,
              placement: "top",
              triggers: "hover",
              content: `${_vm.$t("field.color")}: ${
                _vm.value ? _vm.value : _vm.$t("none")
              }`,
            },
          }),
        ],
        1
      ),
      _c("ColorModal", {
        attrs: { show: _vm.colorShow, color: _vm.value, update: _vm.update },
        on: { changed: _vm.changed, cancel: _vm.cancel },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }